<template>
  <!-- <el-dialog :model-value="dialogVisible" width="1010" class="klinecharts-dialog" @closed="closeDialog" @opened="openDialog"> -->
  <!-- <el-tabs v-model="activeName" @tab-change="handleChange">
    <el-tab-pane :label="$t('日线')" name="Day">
      <div class="charts-area">
      <div class="area-bg"></div>
      <div id="J_EchartDay" class="j_Echart"></div>
    </div>
    </el-tab-pane>
    <el-tab-pane :label="$t('时线')" name="Hour">
      <div class="charts-area">
        <div class="area-bg"></div>
        <div id="J_EchartHour" class="j_Echart"></div>
      </div>
    </el-tab-pane>
    <el-tab-pane :label="$t('K线图')" name="Key">
      <div class="charts-area">
        <div id="J_ChartKey" class="pt-50"></div>
      </div>
    </el-tab-pane>
  </el-tabs> -->
  <div class="relative w-890">
    <div class="flex" style="position: absolute; top: 10px; z-index: 1; left: 20px">
      <div class="my-tabs flex items-center rounded-[10px] bg-blue-50 p-2">
        <div class="tab-item cursor-pointer rounded-[10px] px-16" :class="[activeName === 'Day' ? 'active' : '']" @click="handleChange('Day')">{{ $t('日线') }}</div>
        <div class="tab-item cursor-pointer rounded-[10px] px-16" :class="[activeName === 'Hour' ? 'active' : '']" @click="handleChange('Hour')">{{ $t('时线') }}</div>
        <div class="tab-item cursor-pointer rounded-[10px] px-16" :class="[activeName === 'Key' ? 'active' : '']" @click="handleChange('Key')">{{ $t('K线图') }}</div>
      </div>
    </div>
    <div>
      <div v-if="activeName === 'Day'" class="charts-area">
        <div class="area-bg"></div>
        <div id="J_EchartDay" class="j_Echart"></div>
      </div>
      <div v-if="activeName === 'Hour'" class="charts-area">
        <!-- <div class="area-bg"></div> -->
        <loadingCom v-if="chartloading"></loadingCom>
        <div id="J_EchartHour" class="j_Echart"></div>
      </div>
      <div v-if="activeName === 'Key'" class="charts-area">
        <div id="J_ChartKey" class="pt-50" @click="klineClick"></div>
      </div>
    </div>
  </div>

  <!-- </el-dialog> -->
</template>

<script setup>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { KLineChartPro } from '@klinecharts/pro'
import { CustomDatafeed } from '~/api/customDatafeed'
import { getChart } from '~/api/steam'
import loadingCom from '~/components/common/loading.vue'
import dayjs from 'dayjs'

import '@klinecharts/pro/dist/klinecharts-pro.css'
import { onMounted } from 'vue'
const { t } = useI18n()
const $t = t
const emit = defineEmits(['close'])
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false
  }
})

const newIndexChartStorage = useLocalStorage('newIndexChart')
const newIndexChartStorageObj = newIndexChartStorage.value ? JSON.parse(newIndexChartStorage.value) : {}
const chartloading = ref(false)
const activeName = ref(newIndexChartStorageObj.activeName || 'Day')
const chartData = ref([])

const handleChange = tab => {
  activeName.value = tab
  newIndexChartStorageObj.activeName = tab
  newIndexChartStorage.value = JSON.stringify(newIndexChartStorageObj)
  if (tab === 'Key') {
    setTimeout(() => {
      echartProInit()
    }, 50)
    // echartProInit()
  } else {
    getChartData(tab)
  }
}

const ma7 = ref([])
const ma14 = ref([])
const ma21 = ref([])
const transPerformanceLine = ref([])

const echartOption = ref({})

let myChart = null

onMounted(() => {
  if (activeName.value === 'Key') {
    echartProInit()
  } else {
    getChartData(activeName.value)
  }
})

const openDialog = () => {
  echartProInit()
}

const closeDialog = () => {
  emit('close')
}

// 获取日线和时线数据
const getChartData = tab => {
  chartData.value = []
  chartloading.value = true
  getChart({
    type: tab === 'Day' ? 2 : 1,
    maxTime: ''
  }).then(res => {
    res.data.forEach(item => {
      const time = dayjs(item[0] * 1000).format('YYYY-MM-DD HH:mm:ss')

      chartData.value.push([time, item[1]])
      ma7.value = calculateMA(7)
      ma14.value = calculateMA(14)
      ma21.value = calculateMA(21)
      transPerformanceLine.value.push([time, item[2]])
    })

    setTimeout(() => {
      chartloading.value = false
      echartInit(`J_Echart${tab}`)
    })
  })
}
const localeValue = useI18n().locale.value
// K线图初始化
const echartProInit = () => {
  const J_ChartKey = document.getElementById('J_ChartKey')
  J_ChartKey.innerHTML = ''
  const tempObj = newIndexChartStorageObj.klineStorage ? JSON.parse(newIndexChartStorageObj.klineStorage) : {}
  const myKLineChart = new KLineChartPro({
    container: 'J_ChartKey',
    // 水印
    watermark: '',
    // 标的
    symbol: {
      shortName: $t('大盘指数'),
      priceCurrency: 'cny'
    },
    locale: localeValue === 'zh' ? 'zh-CN' : 'en-US',
    // 副图指标
    subIndicators: tempObj?.subIndicators || ['MACD'],
    // 主图指标
    mainIndicators: tempObj?.mainIndicators || ['MA'],
    // 当前周期
    period: tempObj?.period || { multiplier: 1, timespan: 'hour', text: $t('时K') },
    // 所有周期
    periods: [
      {
        multiplier: 1,
        timespan: 'hour',
        text: $t('时K')
      },
      {
        multiplier: 1,
        timespan: 'day',
        text: $t('日K')
      },
      {
        multiplier: 1,
        timespan: 'week',
        text: $t('周K')
      }
    ],
    // 数据接入api实现
    datafeed: new CustomDatafeed()
  })

  const red = '#F92855'
  const green = '#2DC08E'
  const alphaRed = 'rgba(249, 40, 85, .7)'
  const alphaGreen = 'rgba(45, 192, 142, .7)'

  myKLineChart.setStyles({
    candle: {
      bar: {
        upColor: red,
        downColor: green,
        upBorderColor: red,
        downBorderColor: green,
        upWickColor: red,
        downWickColor: green
      },
      priceMark: {
        last: {
          upColor: red,
          downColor: green
        }
      },
      tooltip: {
        showType: 'standard',
        custom: function () {
          // 这个必须得用方法才能生效，直接用对象没效果
          return [
            { title: 'time', value: '{time}' },
            { title: 'open', value: '{open}' },
            { title: 'high', value: '{high}' },
            { title: 'low', value: '{low}' },
            { title: 'close', value: '{close}' }
            // { title: 'volume', value: '{volume}' }
          ]
        }
      }
    },
    indicator: {
      ohlc: {
        upColor: alphaRed,
        downColor: alphaGreen
      },
      bars: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ],
      circles: [
        {
          style: 'fill',
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          upColor: alphaRed,
          downColor: alphaGreen,
          noChangeColor: '#888888'
        }
      ]
    }
  })
}

// 日线或时线初始化
const echartInit = id => {
  echarts.use([TooltipComponent, GridComponent, DataZoomComponent, LineChart, UniversalTransition, CanvasRenderer, SVGRenderer])

  const chartDom = document.getElementById(`${id}`)

  myChart = echarts.init(chartDom, null, {
    renderer: 'svg'
  })
  echartOption.value = {
    color: ['#308651', '#F92855'],
    grid: {
      top: 60,
      left: 60,
      right: 40
    },
    legend: {
      data: [activeName.value === 'Day' ? $t('日线') : $t('时线'), 'MA7', 'MA14', 'MA21', activeName.value === 'Day' ? $t('市场情绪') : ''],
      selected: {
        [activeName.value === 'Day' ? $t('市场情绪') : '']: false
      },
      top: 15,
      right: 20,
      textStyle: {
        color: '#979CAE'
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    textStyle: {
      fontSize: 14,
      color: '#979CAE'
    },
    xAxis: [
      {
        type: 'time',
        show: false,
        boundaryGap: false
      }
    ],
    yAxis: [
      {
        type: 'value',
        boundaryGap: [0, '10%'],
        min: 'dataMin',
        splitLine: {
          lineStyle: {
            width: 1,
            color: '#EFF1F5'
          }
        }
      },
      {
        type: 'value',
        boundaryGap: [0, '10%'],
        min: 'dataMin',
        splitLine: {
          lineStyle: {
            width: 1,
            color: '#EFF1F5'
          }
        }
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 50,
        end: 100,
        zoomLock: false
      },
      {
        // type: 'inside',
        start: 50,
        end: 100,
        zoomLock: false
      }
    ],
    series: [
      {
        name: activeName.value === 'Day' ? $t('日线') : $t('时线'),
        type: 'line',
        smooth: false,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        lineStyle: { width: 1 },
        color: '#0252D9',
        areaStyle: {
          color: '#0252D9',
          opacity: 0.05
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: chartData.value
      },
      {
        name: 'MA7',
        type: 'line',
        data: ma7.value,
        color: '#308651',
        symbol: 'circle',
        showSymbol: false,
        lineStyle: {
          normal: {
            width: 1,
            opacity: 0.5
          }
        }
      },
      {
        name: 'MA14',
        type: 'line',
        data: ma14.value,
        // smooth: true,
        symbol: 'circle',
        showSymbol: false,
        color: '#DF00FF',
        lineStyle: {
          color: '#308651',
          normal: {
            width: 1,
            opacity: 0.5
          }
        }
      },
      {
        name: 'MA21',
        type: 'line',
        data: ma21.value,
        symbol: 'circle',
        showSymbol: false,
        color: '#BA6766',
        lineStyle: {
          normal: {
            width: 1,
            opacity: 0.5
          }
        }
      },
      {
        name: $t('市场情绪'),
        type: 'line',
        data: activeName.value === 'Day' ? transPerformanceLine.value : null,
        symbol: 'circle',
        showSymbol: false,
        color: '#F87600',
        smooth: 0.2,
        yAxisIndex: 1,
        lineStyle: {
          normal: {
            width: 1,
            opacity: 0.5
          }
        }
      }
    ]
  }
  echartOption.value && myChart.setOption(echartOption.value)
  setTimeout(() => {
    myChart.hideLoading()
  }, 1000)
  myChart.on('dataZoom', function (event) {
    var start = event.start
    var end = event.end
  })
  myChart.on('finished', function (event) {
    // todo 这里做更多数据的加载
    // console.log('datarangeselected', myChart._model.option.dataZoom[0].start)
    if (myChart._model.option.dataZoom[0].start === 0) {
      // console.log('已经触及左边界')
      getmoreData()
    }
  })
}
const loading = ref(false)
const loadAll = ref(false)
const getmoreData = () => {
  if (loading.value || loadAll.value) {
    return false
  }
  loading.value = true
  getChart({
    type: activeName.value === 'Day' ? 2 : 1,
    maxTime: dayjs(chartData.value[0][0]).unix()
  }).then(res => {
    loading.value = false
    if (res.data.length === 0) {
      loadAll.value = true
      return false
    }
    const newData = []
    res.data.forEach(item => {
      const time = dayjs(item[0] * 1000).format('YYYY-MM-DD HH:mm:ss')

      newData.push([time, item[1]])
    })
    chartData.value = newData.concat(chartData.value)
    ma7.value = calculateMA(7)
    ma14.value = calculateMA(14)
    ma21.value = calculateMA(21)
    echartOption.value.dataZoom[0].start = (res.data.length / chartData.value.length) * 100
    echartOption.value.dataZoom[1].start = (res.data.length / chartData.value.length) * 100
    echartOption.value.series[0].data = chartData.value
    echartOption.value.series[1].data = ma7.value
    echartOption.value.series[2].data = ma14.value
    echartOption.value.series[3].data = ma21.value
    myChart.setOption(echartOption.value)
  })
}

const calculateMA = dayCount => {
  const result = []
  const data = chartData.value
  const len = data.length

  if (len < dayCount) {
    return Array(len).fill('-')
  }

  let sum = 0

  // 计算初始的 sum
  for (let i = 0; i < dayCount; i++) {
    sum += data[i][1]
  }

  // 填充前 dayCount 个元素为 '-'
  for (let i = 0; i < dayCount; i++) {
    result.push('-')
  }

  // 计算移动平均值
  for (let i = dayCount; i < len; i++) {
    result.push([data[i][0], (sum / dayCount).toFixed(2)])
    sum = sum - data[i - dayCount][1] + data[i][1]
  }

  return result
}
// K线图初始化
const periods = [
  {
    multiplier: 1,
    timespan: 'hour',
    text: $t('时K')
  },
  {
    multiplier: 1,
    timespan: 'day',
    text: $t('日K')
  },
  {
    multiplier: 1,
    timespan: 'week',
    text: $t('周K')
  }
]
const klineClick = e => {
  const klineStorageObj = newIndexChartStorageObj.klineStorage ? JSON.parse(newIndexChartStorageObj.klineStorage) : {}
  console.log('klineStorageObj', klineStorageObj)
  const innerText = e.target.innerText
  const periodList = [$t('时K'), $t('日K'), $t('周K')]
  console.log('periodList', periodList)
  if (periodList.includes(innerText)) {
    newIndexChartStorageObj.klineStorage = JSON.stringify({
      mainIndicators: klineStorageObj.mainIndicators || ['MA'],
      subIndicators: klineStorageObj.subIndicators || ['VOL'],
      period: periods[periodList.indexOf(innerText)]
    })
    newIndexChartStorage.value = JSON.stringify(newIndexChartStorageObj)
  } else {
    const modal = document.querySelector('.klinecharts-pro-indicator-modal-list')
    if (!modal) return
    const rows = modal.querySelectorAll('li')
    if (!rows || (rows && rows.length === 0)) return

    const main = []
    const sub = []
    let isMainIndicator = true

    for (let i = 1; i < rows.length; i++) {
      // 跳过第一个，因为第一个一定是主图指标
      const row = rows[i]
      const className = row.className
      if (className === 'title') {
        // 此时变换到副图指标
        isMainIndicator = false
        continue
      }
      const checkboxDiv = row.querySelector('.klinecharts-pro-checkbox')
      if (checkboxDiv) {
        const label = checkboxDiv.querySelector('.label')?.textContent?.replace(/\(.*?\)/, '')
        if (checkboxDiv.classList.contains('checked') && label) {
          if (isMainIndicator) {
            main.push(label)
          } else {
            sub.push(label)
          }
        }
      }
    }
    newIndexChartStorageObj.klineStorage = JSON.stringify({
      mainIndicators: main,
      subIndicators: sub,
      period: klineStorageObj.period || { multiplier: 1, timespan: 'day', text: '日K' }
    })
    newIndexChartStorage.value = JSON.stringify(newIndexChartStorageObj)
  }
}
</script>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');
.my-tabs {
  .tab-item {
    height: 34px;
    line-height: 34px;
    &.active {
      background-color: white;
      color: $primary-color;
    }
  }
}
.klinecharts-dialog {
  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      width: 16px;
      height: 16px;
      line-height: 1;
      font-size: 16px;
      top: 16px;
      right: 16px;
      z-index: 10;
    }

    .el-dialog__close {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-family: 'iconfont';
      font-style: normal;

      &:hover {
        animation: loading 0.4s linear;
      }

      &::before {
        content: '\e6d2';
      }

      svg {
        display: none;
      }
    }
  }

  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.el-dialog__body {
  padding: 0;
}
.el-tabs {
  .el-tabs__header {
    margin: 0;
  }

  .el-tabs__active-bar {
    height: 0;
  }

  .el-tabs__nav-wrap::after {
    height: 1px;
  }

  .el-tabs__item {
    height: 60px;
    font-size: 16px;
    padding: 0 15px;
  }
}

.charts-area {
  height: 497px;
  background: $white-color;
  position: relative;

  .klinecharts-pro {
    height: 497px;
  }

  .area-bg {
    // background: url('~/assets/images/detail/chart-bg.png') no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }

  .j_Echart {
    width: 890px;
    height: 497px;
  }
}
</style>
<style lang="scss">
.klinecharts-pro-period-bar .tools:nth-last-child(2) {
  /** 去除截图按钮 */
  display: none;
}

.klinecharts-pro-period-bar .tools:nth-last-child(4) {
  /** 去除时区按钮 */
  display: none;
}
</style>
